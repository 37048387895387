import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Col, Row, Card, Container, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { Spinner } from "react-bootstrap";
import "./styles.css";
import "react-datetime/css/react-datetime.css";
import { ErrorBoundary } from "react-error-boundary";
import fetchData from "./services/fetchData";
import { ModalProvider } from "react-modal-hook";


const Login = lazy(() => import("./pages/Login"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Home = lazy(() => import("./pages/Home"));
const Reports = lazy(() => import("./pages/Reports"));

// because of usage of useHistory hook the Routes should be wrapped into a separate component instead of being nested under App
const CustomRouter = () => {
  const config = {
    clientId: "0oa15do6ouIfbCAw95d7",
    issuer: "https://authenticate.vigilantioe.com/oauth2/aus15ixi53e5rPADi5d7",
    redirectUri: `${window.location.origin}/implicit/callback`,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    cookies: { secure: true },
  };

  const oktaAuth = new OktaAuth(config);

  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) =>
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));

  const onAuthRequired = () => history.push("/login");

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={onAuthRequired}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Suspense
          fallback={
            <div className={"loading-spinner-container"}>
              <Spinner className={"loading-spinner"} animation="border" />
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path={"/implicit/callback"}
              component={LoginCallback}
            />
            <Route path={"/forgot-password"} render={() => <ResetPassword />} />
            <Route path={"/login"} render={() => <Login />} />
            <SecureRoute exact path={"/"} component={Home} />
            <SecureRoute
              exact
              path={"/senate-das-overview"}
              component={Reports}
            />
            <SecureRoute exact path={"/ticket-overview"} component={Reports} />
            <SecureRoute exact path={"/device-overview"} component={Reports} />
            <SecureRoute
              exact
              path={"/supporting-information"}
              component={Reports}
            />
          </Switch>
        </Suspense>
      </div>
    </Security>
  );
};

const App = () => {
//   const [errorData, setErrorData] = useState("");

  const globalErrorHandler = async (error, info) => {
    await fetchData("reportBug", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        errorMessage: error.message,
        componentStack: info.componentStack,
      }),
    });
    // setErrorData({ message: error.message, stack: info.componentStack });
  };

  const errorPage = () => {
    return (
      <>
        <Container>
          <Row>
            <Col xs={12}>
              <Card style={{marginTop: '30vh'}}>
                <Card.Header>Error!</Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={12}>
                      <div>It can happen sometimes.</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div>Submit a bug report by sending a quick email to <a href="mailto:support@vigilantioe.com">support@vigilantioe.com</a></div>
                    </Col>
                    <Col xs={12}>
                        <br />
                      <Button onClick={() => window.location.reload()}>Go Back</Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };
  return (
    <ErrorBoundary FallbackComponent={errorPage} onError={globalErrorHandler}>
      <Router>
        <ModalProvider>
        <CustomRouter />
        </ModalProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
