import {createStore, compose, applyMiddleware} from 'redux';
import reduxThunkFsa from 'redux-thunk-fsa';
import promiseMiddleware from 'redux-promise-middleware';
import {createLogger} from 'redux-logger/src';
import rootReducer from './reducers';

const Store = (initialState = {}) => {
    const environment = process.env.NODE_ENV;

    const middleware = [reduxThunkFsa, promiseMiddleware];

    if (environment !== 'production') {
        middleware.push(createLogger({collapsed: true, duration: true}));
    }

    const enhancers = [];
    let composeEnhancers = compose();

    if (environment !== 'production') {
        const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

        if (typeof composeWithDevTools === 'function') {
            composeEnhancers = composeWithDevTools;
        }
    }

    const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware), ...enhancers));

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers').default;
            store.replaceReducer(nextRootReducer);
        })
    }

    return store;
};

export default Store;
