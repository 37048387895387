const reportsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'STORE_REPORTS':
            return {
                ...state,
                [action.payload.type]: action.payload.data
            };
        case 'UPDATE_REPORTS':
            return state.concat(action.payload);

        default:
            return state;
    }
};
export default reportsReducer;
