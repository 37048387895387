import fetch from 'node-fetch';

const fetchData = (endpoint, options) => {
    let token = '';
    if(JSON.parse(localStorage.getItem('okta-token-storage')) && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken){
        token = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
    } else {
        token = 'NoToken';
    }
    const optionsToUse = !options ? {headers: {'authorization': `Bearer ${token}`}} : {
        ...options,
        headers: {...options.headers, 'authorization': `Bearer ${token}`}
    };

    try {
        return fetch(`${process.env.REACT_APP_FETCH_URL}/${endpoint}`, optionsToUse)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    return res.text();
                }
            })
            .then(result => {
                if (result === 'Jwt is expired') {
                    // alert('The page will be refreshed to get the updated authorization key.', {okButtonStyle: 'secondary'});
                    // window.location.reload(true); // force the page refresh to get the new Jwt
                    throw Error(result);
                } else if (typeof result === 'string') {
                    throw Error(result);
                } else {
                    return result;
                }
            })
            .catch(err => {
                console.error(`${endpoint}`, err);
                return (err);
            });
    } catch (err) {
        console.error(`${endpoint}`, err);
        throw (err);
    }
};

export default fetchData;
