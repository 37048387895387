import {combineReducers} from 'redux';
import userInfoReducer from './userInfoReducer';
import organizationReducer from './organizationReducer';
import inventoryReducer from './inventoryReducer';
import reportsReducer from './reportsReducer';

const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    organizationInfo: organizationReducer,
    inventory: inventoryReducer,
    reports: reportsReducer
});

export default rootReducer;
