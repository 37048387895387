const inventoryReducer = (state = [], action) => {
    switch (action.type) {
        case 'STORE_INVENTORY':
            return action.payload;
        case 'UPDATE_INVENTORY':
            return state.concat(action.payload);

        default:
            return state;
    }
};
export default inventoryReducer;
